import Vue from "vue";
import Vuex from "vuex";
import Login from './Credentials/login'
import sidenav from './Layouts/sidenav'
import dialog from './Layouts/dialogs'
import DropDownUser from '@/store/Main/DropDowns';
import MainCountries from '@/store/modules/main/Countries';
import MasterDataUsers from '@/store/modules/MasterData/users';
import MasterDataDepartment from '@/store/modules/MasterData/department';
import MasterDataDepartmentAccess from '@/store/modules/MasterData/departmentaccess';
import MasterDataRoles from '@/store/modules/MasterData/roles';
import MasterDataVehicles from '@/store/modules/MasterData/vehicles';
import MasterDataWarehouse from '@/store/modules/MasterData/warehouse';
import MasterDataSalesmanposition from '@/store/modules/MasterData/salesman-position';
import MasterDataSalesman from '@/store/modules/MasterData/salesman';
import MasterDataCustomer from '@/store/modules/MasterData/customer';
import MasterDataVendor from '@/store/modules/MasterData/vendor';
import ItStockAdjustment from '@/store/modules/it/stock-adjustment';
import Containers from '@/store/modules/Operations/Containers/Containers';
import Items from '@/store/modules/Sales/Items/Items';
import SalesReports from '@/store/modules/Reports/SalesReports/index';
import WarehouseInventory from '@/store/modules/Operations/Inventory/Inventory';
import Dispatches from '@/store/modules/Logistics/Dispatches/Dispatches'
import StockTransfer from '@/store/modules/Operations/StockTransfer/StockTransfer'
import AcctItemCosting from '@/store/modules/Accounting/ItemCosting/ItemCosting'
import StockRequest from '@/store/modules/Operations/StockRequest/StockRequest'
import StockTransferNew from '@/store/modules/Operations/StockTransferNew/StockTransfer'
import AcctStatementOfAccount from '@/store/modules/Accounting/StatementOfAccount/StatementOfAccount'
import AcctCreditMemo from '@/store/modules/Accounting/CreditMemo/CreditMemo'
import accounting from '@/store/modules/Accounting/accounting'
import Payments from '@/store/modules/Accounting/Payments/Payments'
import StockVariance from '@/store/modules/Operations/StockVariance/StockVariance';
import Inbound from '@/store/modules/Operations/Inbound/Inbound';
import Outbound from '@/store/modules/Operations/Outbound/Outbound';
import SystemSettings from '@/store/Main/SystemSettings/index'
import Promos from '@/store/modules/Sales/Promos/Promos';
import salesOrder from '@/store/modules/Sales/SalesOrder/index'
import WPF from '@/store/modules/Operations/WPF/wpf'
import CF from '@/store/modules/Operations/CF/cf'
import PL from '@/store/modules/Sales/PackingList/packing-list'
import salesquotation from '@/store/modules/Sales/SalesQuotations/SalesQuotations'
import StockCards from '@/store/modules/Operations/StockCards/StockCards'
import WarehouseUser from '@/store/modules/Operations/WarehouseUser/warehouse-user'
import inventory from '@/store/modules/Operations/index'
import AccountingUser from '@/store/modules/Accounting/AccountingUser/accounting_user'
import AccountingBanks from '@/store/modules/Accounting/Banks/Banks'
import WarehouseReturn from '@/store/modules/Operations/WarehouseReturn/WarehouseReturn'
import WarehousePersonnel from '@/store/modules/Operations/WarehousePerosonnel/WarehousePersonnel'
import AcctCreditMemoHistory from '@/store/modules/Accounting/CreditMemoHistory/CreditMemoHistory'
import PackingListHistory from '@/store/modules/Accounting/PackingListHistory/PackingListHistory'
import AccountsPayable from '@/store/modules/Accounting/AccountsPayable/AccountsPayable'
import GeneralSettings from '@/store/Main/SystemSettings/GeneralSettings'
import Camera from '@/store/Layouts/camera';
// import WarehousePersonnel from '@/store/modules/Operations/WarehousePerosonnel/WarehousePersonnel'
// import AcctCreditMemoHistory from '@/store/modules/Accounting/CreditMemoHistory/CreditMemoHistory'
import ContainerDiscrepancy from '@/store/modules/Operations/ContainerDiscrepancy/ContainerDiscrepancy'
import StocksInquiry from '@/store/modules/Sales/StocksInquiry/StocksInquiry'
import MasterDataCluster from '@/store/modules/MasterData/cluster'
import service from '@/store/modules/service'
import reportmodule from '@/store/modules/MasterData/reportmodule'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: false,
  modules: {
    Login,
    sidenav,
    MasterDataUsers,
    MasterDataDepartment,
    MasterDataDepartmentAccess,
    MasterDataRoles,
    MasterDataVehicles,
    MasterDataWarehouse,
    MasterDataSalesmanposition,
    MasterDataSalesman,
    DropDownUser,
    MasterDataCustomer,
    MasterDataVendor,
    MainCountries,
    ItStockAdjustment,
    dialog,
    Containers,
    Items,
    SalesReports,
    WarehouseInventory,
    Dispatches,
    StockTransfer,
    SalesReports,
    AcctItemCosting,
    StockRequest,
    StockTransferNew,
    AcctStatementOfAccount,
    AcctCreditMemo,
    accounting,
    Payments,
    StockVariance,
    Inbound,
    Outbound,
    SystemSettings,
    Promos,
    salesOrder,
    WPF,
    CF,
    PL,
    Promos,
    salesquotation,
    StockCards,
    WarehouseUser,
    AccountingUser,
    AccountingBanks,
    WarehouseReturn,
    inventory,
    WarehousePersonnel,
    AccountsPayable,
    inventory,
    AcctCreditMemoHistory,
    inventory,
    GeneralSettings,
    Camera,
    PackingListHistory,
    ContainerDiscrepancy,
    StocksInquiry,
    MasterDataCluster,
    service,
    reportmodule,
  },
});
